import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/common/seo"
import { COLORS } from "../styles"

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/")
  }, [])

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div style={{ backgroundColor: COLORS.HOLLY_GREEN, height: "100vh" }} />
    </Layout>
  )
}

export default NotFoundPage
